<template>
  <main class="StoresForm">
    <TheSubHeader
      icon="store-alt"
      :title="TheSubHeaderTitle"
      page-title-tag="Stores Form"
    >
      <template #afterButtons>
        <BaseButton
          icon="chevron-left-alt"
          label="voltar"
          theme="icon-left"
          :track="trackSubheaderButtonString('Stores Form', 'back')"
          @click="$router.push('/stores')"
        />
      </template>
    </TheSubHeader>
    <template v-if="!storeId && permissions.storesAdd || storeId && permissions.storesDetail.bind">
      <div class="StoresForm__content">
        <DynamicForm
          ref="form"
          class="StoresForm__form"
          :style="[integrationMethodStyle]"
          :model="form"
          :blueprint="blueprint({rulesFields, disabledFields, hideFields, operationTypeOptions, orderImportTypeOptions, transporters, integrationMethod, storeId, isApiPaxIntegration})"
          :endpoint-errors="endpointErrors"
          submit-type="json"
          @storeInput="updateParentStore"
          @setAddress="setAddress"
          @submit="onSubmit"
        />
      </div>

      <Modal v-if="showModal" @close="goToStoresPage">
        <template #header>
          <div class="Modal__title">
            <p>Confirmação</p>
          </div>
        </template>

        <template #body>
          <div class="Modal__body">
            <p>{{ modalMessage }}</p>
            <BaseButton
              label="Confirmar"
              theme="icon-right"
              filled
              color="dark"
              icon="check"
              text-color="white"
              @click="goToStoresPage"
            />
          </div>
        </template>
      </Modal>
    </template>
  </main>
</template>

<script>
import { stores } from '@/api'
import { mapState, mapActions } from 'vuex'
import { BaseButton } from '@/components/atoms'
import { DynamicForm, Modal } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import blueprint from './StoresForm.blueprint'

export default {
  name: 'StoresForm',
  components: {
    TheSubHeader,
    DynamicForm,
    BaseButton,
    Modal
  },

  data() {
    return {

      //Form
      form: {
        parent_id: null,
        name: '',
        cnpj: '',
        phone: '',
        email: '',
        operation_type: null,
        orderImportType: null,
        transporters: [],
        uses_dropoff: false,
        cte_emit: false,
        zip: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state_id: null,
        status_id: {},
        // blig fields
        bling: {
          code_store_bling: null,
          api_key: null,
          ids_services: null
        },
        // notfis fields
        ftp: {
          host: null,
          user: null,
          pass: null,
          path: null,
          path_backup: null,
          path_error: null,
          path_ocorren: null
        },
        // intelipost
        intelipost: {
          'api-key': null,
          'origin-name': null
        },
        // api pax
        apiPax: {
          'api-key': null,
          'origin-name': null
        }
        
      },

      //Select Options
      operationTypeOptions: [
        {id: 'delivery', name: 'Entrega',}
      ],
      orderImportTypeOptions: [
        {id: 'spreadsheet', name: 'Planilha'},
        {id: 'bling', name: 'Api - Bling'},
        {id: 'proceda-5', name: 'Proceda - Notfis 5.0'},
        {id: 'intelipost', name: 'Intelipost'},
        {id: 'api-pax-v1', name: 'Api Pax'}
      ],

      //Helpers
      showModal:  false,

      //Errors
      endpointErrors: {},

      formBind: {
        transporter_id: null,
        transporter_code: ''
      },
      // currentPage: 1
    }
  },
  computed: {
    ...mapState({
      storesParents: state => state.stores.storesParents,
      transporters: state => state.transporters.transporters,
      storesTransporters: state => state.stores.storesTransporters,
      permissions: state => state.auth.permissions,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    storeId() {
      return this.$route.params.id
    },

    TheSubHeaderTitle() {
      return this.storeId ? 'Editar lojista' : 'Cadastro de Lojista'
    },

    //Rules Fields
    rulesFields() {
      return {
        email: !!this.form.email,
        phone: !!this.form.phone
      }
    },

    //Disabled Fields
    disabledFields() {
      return {
        store: !!this.storeId
      }
    },

    //Hide Fields
    hideFields() {
      return {
        cteEmit: true
      }
    },

    // Create or update modal message
    modalMessage() {
      return this.storeId ? 'O cadastro foi atualizado com sucesso!' : 'A loja foi criada com sucesso'
    },

    // Stores exists
    storeExists() {
      return this.form.parent_id && !!this.form.parent_id.status_id
    },

    // Has mail
    hasEmail() {
      return !!this.form.email
    },

    // Has phone
    hasPhone() {
      return !!this.form.phone
    },

    // Return integration type
    integrationMethod() {
      return this.form.orderImportType?.id
    },

    // Return if has ApiPax key and origin name
    isApiPaxIntegration() {
      return !!(this.form.apiPax['api-key'] && this.form.apiPax['origin-name'])
    },

    // Return integration method style
    integrationMethodStyle() {
      let styleFields = ''

      if(this.integrationMethod === 'intelipost' ||  this.integrationMethod === 'api-pax-v1') {
        styleFields = '"api-key api-key api-key origin-name origin-name origin-name . . . . . ."'
      } else if(this.integrationMethod === 'proceda-5') {
        styleFields = `"host host host user user user pass pass . . . ."
        ". . . . . . . . . . . ."
        "path path path  path_backup path_backup path_backup path_error path_error path_error path_ocorren path_ocorren path_ocorren"`
      }

      const emptyStyle = '". . . . . . . . . . . ."'

      const integrationMethod = this.integrationMethod === 'intelipost' ||  this.integrationMethod === 'api-pax-v1' || this.integrationMethod === 'proceda-5'

      const integrationStyle = integrationMethod ? styleFields : emptyStyle
      
      return {'--integration-method-style': integrationStyle}
    }
  },

  mounted() {
    if (this.storeId) this.fetchStore()
    this.fetchStoresParents()
    this.fetchTransporters()
  },

  methods: {
    blueprint,
    ...mapActions({
      setLoading: 'loading/setLoading',
      fetchStoresParents: 'stores/fetchStoresParents',
      fetchTransporters: 'transporters/fetchTransporters',
      fetchStoresTransporters: 'stores/fetchStoresTransporters',
      bindStoreTransporter: 'stores/bindStoreTransporter',
      removeStoreTransporter: 'stores/removeStoreTransporter',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination'
    }),

    setAddress(data) {
      this.form = {...this.form, ...data}
    },

    updateParentStore(newTag) {
      const isArray = Array.isArray(newTag)

      if (isArray) {
        this.storeExists ? null : this.storesParents.push(newTag[0])
        this.form.parent_id = newTag[0]
      }

      if (!isArray) {
        this.storeExists ? null : this.storesParents.push(newTag)
        this.form.parent_id = newTag
      }
    },

    // Fetch store
    async fetchStore() {
      await stores.fetchStore({ id: this.storeId }, (res) => {
        delete res.address.name
        delete res.address.state_id

        res = {
          ...res,
          ...res.address
        }

        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }

        if (res.info.import_rule !== null && res.integrations_params) {
          this.setIntegrationType(res)
        }
      }, (e) => e)
    },

    // Set integration type in fetch
    setIntegrationType(data) { 
      if (data.info?.import_rule === 'bling') {
        this.form.orderImportType = { id: 'bling', name: 'Api - Bling' }

        data.integrations_params.map(el => {
          this.form.bling[el.key] = el.value
        })
      }
          
      const integration = data.integrations?.find(el => el.type === 'input')

      if(!integration) {
        return false
      }

      if (integration.format === 'notfis-proceda-5') {
        this.form.orderImportType = { id: 'proceda-5', name: 'Proceda - Notfis 5.0' }

        data.integrations_params.map(el => {
          this.form.ftp[el.key] = el.value
        })
      }

      if (integration.format === 'intelipost') {
        this.form.orderImportType = { id: 'intelipost', name: 'Intelipost' }

        data.integrations_params.map(el => {
          this.form.intelipost[el.key] = el.value
        })
      }

      if(integration.format === 'pax') {
        this.form.orderImportType = { id: 'api-pax-v1', name: 'Api Pax' }

        data.integrations_params.map(el => {
          this.form.apiPax[el.key] = el.value
        })
      }
    },

    async removeTransporter(e) {
      this.removeStoreTransporter({ transporterId: e.id, storeId: this.storeId })
    },

    async bindTransporter() {
      const body = {
        ...this.formBind,
        store_id: this.storeId,
        transporter_id: this.formBind.transporter_id.id
      }

      this.bindStoreTransporter(body).then( () => {
        this.formBind.transporter_id = null
        this.formBind.transporter_code = ''
      })
    },

    // Create store
    async createStore(body) {
      this.setLoading(true)

      const data = {...body}

      Object.assign(
        data,
        {
          parent_id: this.form.parent_id.id,
          orderImportType: this.form.orderImportType.id,
          state_id: this.form.state_id ? this.form.state_id.id : null,
          operation_type: this.form.operation_type.id,
          transporters: data.transporters.map(el => el.id)
        }
      )

      if(this.form.number) Object.assign(data, { number: parseInt(this.form.number) })

      if(this.form.bling.code_store_bling) {
        Object.assign(
          data, {
            bling: {
              code_store_bling: parseInt(this.form.bling.code_store_bling),
              api_key: this.form.bling.api_key,
              ids_services: this.form.bling.ids_services
            }
          }
        )
      }

      this.deletePostBodyFields(data)

      await stores.createStore(
        data,
        () => this.showModal = true,
        (e) => (this.endpointErrors = e.validation),
        () => this.setLoading(false)
      )
    },

    // Update store
    async updateStore(body) {
      const data = this.deletePostBodyFields({...body})

      Object.assign(
        data,
        {
          parent_id: this.form.parent_id.id,
          orderImportType: this.form.orderImportType.id,
          state_id: this.form.state_id ? this.form.state_id.id : null,
          operation_type: this.form.operation_type.id,
          transporters: data.transporters.map(el => el.id)
        }
      )

      if(this.form.number) Object.assign(data, { number: parseInt(this.form.number) })

      this.setLoading(true)

      await stores.updateStore({ id: this.storeId, body: data }, () =>  this.showModal = true, (e) => this.endpointErrors = e.validation, () => this.setLoading(false))
    },

    // Delete unused payload fields
    deletePostBodyFields(body) {
      if(body.undefined) {
        delete body.undefined
      }

      if(!this.hasPhone) {
        delete body.phone
      }

      if(!this.hasEmail) {
        delete body.email
      }

      if(!this.storeExists) {
        Object.assign(body, {'parent': this.form.parent_id?.name})

        delete body.parent_id
      }

      if(this.integrationMethod !== 'bling') {
        delete body.bling
      }

      if(this.integrationMethod !== 'proceda-5') {
        delete body.ftp
      }

      if(this.integrationMethod !== 'intelipost') {
        delete body.intelipost
      }

      if(this.integrationMethod !== 'api-pax-v1') {
        delete body.apiPax
      }

      return body
    },

    async onSubmit(data) {
      this.storeId ? this.updateStore(data) : this.createStore(data)
    },

    goToStoresPage() {
      this.$router.push('/stores')
    }
  }
}
</script>

<style lang="scss" scoped>
.StoresForm {
  &__content,
  &__divider,
  &__transporters {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas:
      ". main .";
    grid-column-gap: 1rem;
  }

  &__dividerContent,
  &__transportersContent{
    grid-area: main;
  }

  &__dividerContent{
    display: flex;
    align-items: center
  }

  &__transportersContent{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__bind{
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    align-items: end;
    grid-gap: 30px;
  }

  &__form {
    grid-template-areas:
      "labelDetails labelDetails . . . . . . . . . ."
      "parent_id parent_id parent_id name name name cnpj cnpj phone phone email email"
      "operation_type operation_type operation_type transporters transporters uses_dropoff uses_dropoff cte_emit cte_emit . . ."
      "address address address address address address address address address address address address"
      "integrationDetails integrationDetails . . . . . . . . . ."
      "orderImportType orderImportType orderImportType . . . . . . . . ."
      "api_key api_key api_key ids_services ids_services ids_services code_store_bling code_store_bling code_store_bling . . ."
      var(--integration-method-style)
      ". . . . . . . . . . confirm confirm";
  }

  &__divider {
    background: $color-neutral-stronger;
    min-height: 40px;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  &__label {
    color: $color-neutral-softest;
    font-weight: $font-weight-bold;
    margin-left: 20px;
    text-transform: uppercase;
    font-size: $font-size-1xmini;
  }

  &__table {
    margin: 30px 0;
  }
}
</style>

<style lang="scss">
.StoresForm {
  .DynamicForm__parent_id {
    .multiselect__tags {
      height: 35px;
    }
  }


  .Modal {
    .Modal__container {
      width: 400px;
      height: 150px;

      .Modal__title {
        padding-left: 20px;
        padding-top: 7px;
      }

      .Modal__body {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & p {
          margin-bottom: 15px;
        }

        .BaseButton {
          width: 50%;
        }
      }
    }

  }
}
</style>