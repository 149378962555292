export default ({ rulesFields, disabledFields, hideFields, operationTypeOptions, orderImportTypeOptions, transporters, integrationMethod, storeId, isApiPaxIntegration }) => {

  const cteEmit = [
    {
      id: 'cte_emit',
      model: 'cte_emit',
      tag: 'BaseCheckBox',
      style: 'align-self: end',
      props:{
        name: 'Essa loja emite CTE?',
      }
    }
  ]

  const blingFields = [
    {
      id: 'api_key',
      model: {
        bling: 'api_key'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Api-key',
        invalidLabel: 'Insira uma Api-key correta'
      }
    },
    {
      id: 'ids_services',
      model: {
        bling: 'ids_services'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Id serviço',
        invalidLabel: 'Insira um id de serviço correto'
      }
    },
    {
      id: 'code_store_bling',
      model: {
        bling: 'code_store_bling'
      },
      tag: 'InputGroup',
      props: {
        readOnly: !!storeId,
        label: 'Código da Loja',
        invalidLabel: 'Insira um código correto',
        mask: 'number'
      }
    }
  ]

  const notfisFields = [
    {
      id: 'host',
      model: {
        ftp: 'host'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Host',
        invalidLabel: 'Insira um host correto'
      }
    },
    {
      id: 'user',
      model: {
        ftp: 'user'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Usuário',
        invalidLabel: 'Insira um usuário correto'
      }
    },
    {
      id: 'pass',
      model: {
        ftp: 'pass'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        type: 'password',
        label: 'Senha',
        invalidLabel: 'Insira uma senha correta'
      }
    },
    {
      id: 'path',
      model: {
        ftp: 'path'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Notfis - Pasta de Captura',
        invalidLabel: 'Insira uma pasta correta'
      }
    },
    {
      id: 'path_backup',
      model: {
        ftp: 'path_backup'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Notfis - Pasta ao salvar os arquivos processados',
        invalidLabel: 'Insira uma pasta correta'
      }
    },
    {
      id: 'path_error',
      model: {
        ftp: 'path_error'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Notfis - Pasta ao salvar erros no processamento',
        invalidLabel: 'Insira uma pasta correta'
      }
    },
    {
      id: 'path_ocorren',
      model: {
        ftp: 'path_ocorren'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Ocoren - Pasta ao salvar',
        invalidLabel: 'Insira uma pasta correta'
      }
    }
  ]

  const intelipostFields = [
    {
      id: 'api-key',
      model: {
        intelipost: 'api-key'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Api-key',
        invalidLabel: 'Insira uma Api-key correta'
      }
    },
    {
      id: 'origin-name',
      model: {
        intelipost: 'origin-name'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Origin-name',
        invalidLabel: 'Insira um origin-name correto'
      }
    },
  ]

  const apiPaxFields = [
    {
      id: 'api-key',
      model: {
        apiPax: 'api-key'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Api-key',
        invalidLabel: 'Insira uma Api-key correta'
      }
    },
    {
      id: 'origin-name',
      model: {
        apiPax: 'origin-name'
      },
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        label: 'Origin-name',
        invalidLabel: 'Insira um origin name correto'
      }
    },
  ]

  return [
    {
      id: 'labelDetails',
      tag: 'label',
      content: 'Detalhes da loja'
    },
    {
      id: 'parent_id',
      model: 'parent_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Lojista principal',
        label: 'Lojista principal',
        placeholder: 'Selecione',
        allowCreate: true,
        selectOptions: null,
        fetchOptions: 'stores/fetchStoresParentsAsync',
        invalidLabel: 'Vincule ao menos uma loja',
        disabled: disabledFields.store
      },
      events: {
        input: 'storeInput',
      }
    },
    {
      id: 'name',
      model: 'name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome da loja',
        invalidLabel: 'Insira um nome'
      }
    },
    {
      id: 'cnpj',
      model: 'cnpj',
      tag: 'InputGroup',
      rules: ['required', 'cnpj'],
      props: {
        label: 'CNPJ',
        mask: 'cnpj',
        invalidLabel: 'Insira um CNPJ'
      }
    },
    {
      id: 'phone',
      model: 'phone',
      tag: 'InputGroup',
      rules: rulesFields.phone ? ['phone'] : [],
      props: {
        label: 'Telefone',
        mask: 'phone',
        invalidLabel: 'Insira um número de Telefone'
      }
    },
    {
      id: 'email',
      model: 'email',
      tag: 'InputGroup',
      rules: rulesFields.email ? ['email'] : [],
      props: {
        label: 'E-mail',
        invalidLabel: 'Insira um E-mail válido'
      }
    },
    {
      id: 'operation_type',
      model: 'operation_type',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Tipo da operação',
        label: 'Tipo da operação',
        placeholder: 'Selecione',
        autoSelect: true,
        selectOptions: operationTypeOptions,
        invalidLabel: 'Selecione um tipo de operação',
        clearable: false,
      }
    },
    {
      id: 'transporters',
      model: 'transporters',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Vincular Transportadoras',
        label: 'Vincular Transportadoras',
        placeholder: 'Selecione',
        tags: true,
        multiple: true,
        selectOptions: transporters,
        invalidLabel: 'Vincule ao menos uma transportadora',
      },
    },
    {
      id: 'uses_dropoff',
      model: 'uses_dropoff',
      tag: 'BaseCheckBox',
      style: 'align-self: end',
      props:{
        name: 'Essa loja utiliza dropoff?',
      }
    },

    ...(hideFields.cteEmit ? cteEmit : []),

    {
      id: 'address',
      tag: 'InputAddress',
      props: {
        fields: {
          zip: 'zip',
          address: 'address',
          number: 'number',
          complement: 'complement',
          neighborhood: 'neighborhood',
          city: 'city',
          state_id: 'state_id'
        },
        allowEmptyNumber: true
      }
    },

    {
      id: 'integrationDetails',
      tag: 'label',
      content: 'Detalhes da integração'
    },
    {
      id: 'orderImportType',
      model: 'orderImportType',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        readOnly: !!storeId,
        name: 'Tipo da integração',
        label: 'Tipo da integração',
        placeholder: 'Selecione',
        autoSelect: true,
        selectOptions: orderImportTypeOptions,
        invalidLabel: 'Selecione um tipo de integração',
        clearable: false,
      }
    },

    // blingFields
    ...(integrationMethod === 'bling' ? blingFields : []),

    // notfisFields
    ...(integrationMethod === 'proceda-5' ? notfisFields : []),

    // intelipostFields
    ...(integrationMethod === 'intelipost' ? intelipostFields : []),

    // apiPaxFields
    ...(integrationMethod === 'api-pax-v1' && isApiPaxIntegration ? apiPaxFields : []),

    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Salvar',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true
      }
    }
  ]
}